import React from 'react';
import T from 'i18n';
import {
  Button,
  Chip,
  IconButton,
  ListItem,
  Menu,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FilterListOutlined, SendOutlined, TuneOutlined } from '@mui/icons-material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useFetchMatches } from '../redux/fetchMatches';
import { useGenerateAnswers } from '../redux/generateAnswers';
import SearchOptions from './SearchOptions';
import RecentSearchesList from './RecentSearchesList';
import SearchInputLocations from './SearchInputLocations';
import { isMobile } from 'utils/device';
import DocumentsList from './DocumentsList';

interface SearchInputProps {
  filters: any;
  handleChangeFilters: Function;
  handleSearch: Function;
  removeFolders?: boolean;
  topic?: string;
  locationsList: Array<any>;
  isNewSearch?: boolean;
  containsMatches?: boolean;
}

const SearchFiltersChip = ({ filters, removeFolders }: any) => {
  return (
    <>
      {filters.location.length === 0 && filters.files.length === 0 && 'Filter'}
      {!removeFolders && filters.location.length > 0 && (
        <Typography sx={{ display: 'inline', color: 'GrayText' }}>
          <b>{T.translate('case.folders')}</b> : {filters.location[0].name}
          {filters.location.length > 1 ? ` & ${filters.location.length - 1} more ...` : ''}{' '}
        </Typography>
      )}
      {!removeFolders && filters.files.length > 0 && (
        <Typography sx={{ display: 'inline', color: 'GrayText' }}>
          <b>{T.translate('case.files')}</b> : {filters.files[0].id}
          {filters.files.length > 1 ? ` & ${filters.files.length - 1} more ...` : ''}
        </Typography>
      )}
      {removeFolders && filters.location.length > 0 && (
        <Typography sx={{ display: 'inline', color: 'GrayText' }}>
          <b>{T.translate('case.matches')}</b> : {filters.location[0].name}
          {filters.location.length > 1 ? ` & ${filters.location.length - 1} more ...` : ''}{' '}
        </Typography>
      )}
    </>
  );
};

export default function SearchInput({
  filters,
  handleChangeFilters,
  handleSearch,
  removeFolders,
  topic,
  locationsList,
  isNewSearch,
  containsMatches,
}: SearchInputProps) {
  const inpRef = React.useRef<HTMLInputElement>(null);
  const filtersScrollbarRef = React.useRef<any>(null);
  const { fetchDocumentsPending } = useFetchMatches();
  const { generateAnswersPending } = useGenerateAnswers();
  const locationsMenu = locationsList.filter(item =>
    removeFolders ? item.type === 'matches' : item.type !== 'matches',
  );
  const [searchOptionsAnchorEl, setSearchOptionsAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const openSearchOptions = Boolean(searchOptionsAnchorEl);
  const [recentSearchesAnchorEl, setRecentSearchesAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const openRecentSearches = Boolean(recentSearchesAnchorEl);
  const [filtersMenu, setFiltersMenu] = React.useState<null | HTMLElement>(null);
  const openFiltersMenu = Boolean(filtersMenu);

  const handleRecentSearchesMenuClose = () => {
    setRecentSearchesAnchorEl(null);
  };

  return (
    <div
      style={{
        border: '1px  solid #212529',
        borderRadius: '0.5rem',
        height: '8rem',
        padding: '0.5rem',
      }}
    >
      <TextField
        inputRef={inpRef}
        id="ai-search-input"
        variant={'standard'}
        fullWidth
        placeholder={
          isNewSearch
            ? T.translate('case.topicInputPlaceholder')
            : T.translate('case.questionInputPlaceholder')
        }
        sx={{
          borderRadius: '1rem',
          '.MuiOutlinedInput-root': { p: 0 },
          padding: '0.5rem',
        }}
        value={filters.phrase}
        onChange={e => {
          handleChangeFilters({ phrase: e.target.value });
          if (e.target.value && e.target.value.length < 3 && !openRecentSearches) {
            setRecentSearchesAnchorEl(e.currentTarget);
          } else if (openRecentSearches && e.target.value.length >= 3) {
            setRecentSearchesAnchorEl(null);
          }
        }}
        onKeyDown={(e: { key: string; stopPropagation: Function; target: any }) => {
          e.stopPropagation();
          if (e.target.value && e.key === 'Enter') {
            handleSearch();
          }
        }}
        onClick={() => inpRef.current?.focus()}
        autoFocus
        autoComplete="off"
        disabled={fetchDocumentsPending || generateAnswersPending}
        InputProps={{ disableUnderline: true, sx: { fontSize: '1.3rem' } }}
      />
      <div
        style={{
          marginTop: '0.5rem',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Stack direction="row" spacing={1}>
          <Chip
            size="small"
            icon={<FilterListOutlined />}
            sx={{ backgroundColor: '#F7F7F8', fontSize: '1rem' }}
            label={<SearchFiltersChip filters={filters} removeFolders={removeFolders} />}
            clickable
            onClick={e => setFiltersMenu(e.currentTarget)}
          />
          <Chip
            size="small"
            icon={<TuneOutlined />}
            sx={{ backgroundColor: '#F7F7F8', fontSize: '1rem' }}
            label="Options"
            clickable
            onClick={e => setSearchOptionsAnchorEl(e.currentTarget)}
          />
        </Stack>
        <IconButton disabled={filters.phrase?.length === 0} onClick={() => handleSearch()}>
          <SendOutlined />
        </IconButton>
      </div>
      {openSearchOptions && searchOptionsAnchorEl && (
        <SearchOptions
          open={openSearchOptions}
          anchorEl={searchOptionsAnchorEl}
          handleClose={() => {
            setSearchOptionsAnchorEl(null);
            const searchInp = document.getElementById('ai-search-input');
            if (searchInp) {
              searchInp.click();
            }
          }}
        />
      )}
      {filtersMenu && openFiltersMenu && (
        <Menu
          anchorEl={filtersMenu}
          open={openFiltersMenu}
          onClose={() => setFiltersMenu(null)}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          sx={{ maxWidth: isMobile ? '90vw' : '60vw' }}
        >
          <ListItem sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography sx={{ color: 'GrayText' }}>Locations</Typography>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                handleChangeFilters({
                  location:
                    locationsMenu && locationsMenu.map(itm => itm.id).includes('selectedMatches')
                      ? [{ id: 'allMatches', name: 'All Matches', type: 'matches' }]
                      : [],
                  files: [],
                });
              }}
            >
              {T.translate('common.clear')}
            </Button>
          </ListItem>
          <PerfectScrollbar
            style={{ maxHeight: '70vh' }}
            containerRef={ref => (filtersScrollbarRef.current = ref)}
          >
            {!containsMatches && filters.files?.length > 0 && (
              <DocumentsList filters={filters} handleChangeFilters={handleChangeFilters} />
            )}
            <SearchInputLocations
              locationsMenu={locationsMenu}
              filters={filters}
              handleChangeFilters={handleChangeFilters}
              removeFolders={removeFolders}
              filtersScrollbarRef={filtersScrollbarRef}
            />
          </PerfectScrollbar>
        </Menu>
      )}
      {openRecentSearches && recentSearchesAnchorEl && (
        <RecentSearchesList
          handleRecentSearchesMenuClose={handleRecentSearchesMenuClose}
          anchorEl={recentSearchesAnchorEl}
          openRecentSearchesMenu={openRecentSearches}
          removeFolders={removeFolders}
          topic={topic}
          handleChangeFilters={handleChangeFilters}
          handleSearch={handleSearch}
        />
      )}
    </div>
  );
}
