import React from 'react';
import T from 'i18n';
import { Skeleton } from '@mui/material';

type BatchUploadingProps = {
  uploadComplete: boolean;
};

const BatchUploading = ({ uploadComplete }: BatchUploadingProps) => {
  return (
    <section className="case-file-drop-zone">
      <div
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <h3 style={{ color: '#908f90' }}>
          {uploadComplete
            ? T.translate('case.batchUpload.uploadSuccessful')
            : T.translate('case.batchUpload.processing')}
          {uploadComplete && <Skeleton variant="text" sx={{ fontSize: '1rem' }} />}
        </h3>
      </div>
    </section>
  );
};

export default BatchUploading;
